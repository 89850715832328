import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'

import SEO from "../components/seo"
import Layout from "../components/layout"
import Navbar from "../components/Navbar"
import Hero from '../components/Hero'
import PageSection from '../components/PageSection'
import ContactForm from '../components/ContactForm'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Counselling services in York" description="Find out about integrative counselling services in York and Leeds." keywords="psychological therapy, counselling york, anxiety therapy, counseling services" lang="en" />

    <Navbar />

    <Hero
      scroll
      heading="Counselling in North Yorkshire"
      subheading="Hanna Grzesik"
      image={data.heroImage.childImageSharp.fluid.src} />
    <PageSection heading="Welcome">
      <p className="has-text-justified">As human beings we usually find comfort in sharing our stories with others. However, sometimes we need someone objective, with specialised psychological knowledge, who is not a family member or a friend, to listen and "hear our story", so we can get a better idea of our options or gain more clarity on our thoughts and feelings. </p>
      <p>Therapy could be a great tool to improve our sense of well-being and move from surviving to thriving. People seek therapy for many different reasons, for example, because of anxiety, childhood trauma, low self-esteem, shame, general or work related stress, cultural adjustment, complicated family relationships, social anxiety, life changing events, bereavement, PTSD, emotional dysregulation. </p>
      <p>You don't need to be in a crisis to seek counselling. You may already be managing fairly well in some areas of your life but maybe need support with an area or situation that you feel stuck in and find difficult to change on your own.</p>
    </PageSection>
    <PageSection id="about-emdr" heading="About EMDR">
      <p className="has-text-justified">EMDR, or Eye Movement Desensitization and Reprocessing, is a powerful type of psychotherapy, recognised by the World Health Organisation (WHO) and the National Institute for Health and Care Excellence (NICE). It helps to recover from past traumatic experiences and is often recommended for PTSD. </p>
      <p>EMDR therapy uses a combination of eye movements, sounds, or taps, to help the brain process and make sense of these memories. This can help to bring the memory to the surface, where it can be processed and understood in a new way.</p>
      <p className="has-text-justified">EMDR therapy is typically considered a short-term treatment, with most people seeing improvement in their symptoms after only a few sessions. It is important to note that EMDR therapy is not suitable for everyone, and it should be done under the guidance of a trained therapist.</p>
      <p className="has-text-justified">You can read more about EMDR here:</p>
      <ul>
        <li><a href="https://emdrassociation.org.uk/discover-emdr/the-basics/" target="_blank" rel="noopener">EMDR: The basics (EMDR Association)</a></li>
        <li><a href="https://www.youtube.com/watch?v=Pkfln-ZtWeY" target="_blank" rel="noopener">Introduction to EMDR Therapy (YouTube)</a></li>
        <li><a href="https://www.ptsduk.org/treatment-help/eye-movement-desensitisation-reprocessing-emdr/" target="_blank" rel="noopener">Eye Movement Desensitisation Reprocessing (EMDR) for PTSD and C-PTSD (PTSDUK)</a></li>
      </ul>
      <p className="has-text-justified">If you are interested in the EMDR therapy please get in touch.</p>
    </PageSection>
    <Hero scroll image={data.section1Image.childImageSharp.fluid.src} />
    <PageSection id="training-and-experience" heading="Training & Experience">
      <p className="has-text-justified">I’m an accredited Counsellor with over 15 years of experience. I provide 1:1 counselling for adults, offering face-to-face sessions in York and remote sessions nationwide. </p>
      <p>Following the achievement of MA in Psychology in 2006, I trained in solution-focused therapy with the Polish Milton Erickson Institute accredited by The Milton H. Erickson Foundation. I worked as a qualified Psychologist in Poland providing individual and group psychotherapy, psychological interventions and psychoeducational workshops. In 2019 I completed a Post Graduate Diploma in Counselling and Psychotherapy, level 7 at Leeds Beckett University. </p>
      <p>Since moving to the UK in 2013, I have worked in various settings, including community-based services and local charities. I am also trained as EMDR therapist (Part 1-3 of accredited training provided by EMDR Masterclass). I'm an accredited member of the National Counselling and Psychotherapy Society MNCS (Prof Accred) and a member of the EMDR UK Association. I work within their Ethical Framework and I am professionally supervised.</p>
      {/* <Img fixed={data.section1Image.childImageSharp.fixed} /> */}
      <div className="columns is-centered is-mobile">
        <a href="https://www.nationalcounsellingsociety.org/counsellors/NCS19-08399" target="_blank" rel="noopener">
          <Img fixed={data.accreditationLogo.childImageSharp.fixed} />
        </a>
      </div>
      <div className="columns is-centered is-mobile" style={{ marginTop: '10px' }}>
        {/* <!-- Professional verification provided by Psychology Today --> */}
        <a href="https://www.psychologytoday.com/profile/708452" className="sx-verified-seal"></a>
        <script type="text/javascript" src="https://member.psychologytoday.com/verified-seal.js" data-badge="13" data-id="708452" data-code="aHR0cHM6Ly93d3cucHN5Y2hvbG9neXRvZGF5LmNvbS9hcGkvdmVyaWZpZWQtc2VhbC9zZWFscy9bQkFER0VdL3Byb2ZpbGUvW1BST0ZJTEVfSURdP2NhbGxiYWNrPXN4Y2FsbGJhY2s="></script>
        {/* <!-- End Verification --> */}
      </div>
    </PageSection>
    <PageSection id="how-i-work" heading="How I work" >
      <p className="has-text-justified">My core counselling training stems from solution focused and relational therapy. I have also developed a range of interventions, drawing upon techniques from various therapeutic and scientific approaches. Solution focus therapy promotes positive changes by encouraging you to focus on what you can do, rather than what you can’t, recognises your strengths and identifies strategies that work for you and your current situation. It focuses mostly on the here and now and the future. It dwells into the past only if it’s necessary to unblock the present. I also incorporate relational approach therefore the therapeutic process always remains at the centre of the work we will do together. Counselling sessions can be a space for you to discover it within a supportive and non-judgmental environment. In our initial session we will discuss the practical aspects of counselling contract, your expectations and the changes you would like to make as well as my way of working to see if this suits your needs. </p>
      <h3 style={{ marginTop: '50px', textAlign: 'left', fontWeight: '300' }}>Please note that currently I'm unable to accept referrals for the following:</h3>
      <ul className="general-list">
        <li>Under 18s</li>
        <li>High risk of self-harm</li>
        <li>Psychosis and Schizophrenia</li>
        <li>Ongoing heavy drug and/or alcohol abuse</li>
        <li>Forensic patients</li>
        <li>Brain injury</li>
      </ul>
    </PageSection>
    <Hero scroll image={data.mugImage.childImageSharp.fluid.src} />
    <PageSection id="contact" heading="Contact Information" backgroundColor="rgba(245,239,238,.5)">
      <p className="has-text-justified">If you would like to arrange the first session please get in touch. Often I will be with clients, therefore if I can't answer your call, a better way to reach me is by sending a short email (Get in touch via <a href="https://nationalcounsellingsociety.org/counsellors/NCS19-08399">the National Counselling Society contact form</a>), 
      or send a text <a href="tel:+447378196588">07378196588</a>. We can then find a convenient time to arrange a call back.</p>
      <p>Unsure what to write? Simply introduce yourself, state what kind of therapy you are looking for (short-term, long-term, face to face, remote) and tell me about your availability during the week. You can mention very briefly the main issue you are hoping to focus on in the sessions but please be mindful there is no need to go into details in the initial email. This is something we can explore more in the first session or during the initial call.</p>
      <p>I use a therapy office in York city centre, 10 minutes walking distance from the Railway Station. The nearest car park is located at the Clifford's Tower and near the Bishopthorpe Road.</p>
      <p>I also offer remote sessions.</p>
      <p><strong>Your investment: </strong>£55 per 1 session (50 minutes face-to-face).</p>
      <p>Get in touch via <a href="https://nationalcounsellingsociety.org/counsellors/NCS19-08399">the National Counselling Society contact form.</a></p>
      {/*<p><strong style={{color : 'red'}}>CONTACT FORM IS TEMPORARILY TURNED OFF, PLEASE DON'T USE IT NOW, IT WILL BE BACK SOON</strong></p>*/}
      {/*<ContactForm />*/}
    </PageSection>
  </Layout>
)

export const gqlData = graphql`
query indexPageEn{
  heroImage: 
    file(relativePath: { eq: "francesco-gallarotti-2498-unsplash.jpg"}){
      childImageSharp{
        fluid(maxWidth: 1200, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }

  section1Image: 
    file(relativePath: { eq: "francesco-gallarotti-rQqgFsRgOqA-unsplash.jpg"}){
      childImageSharp{
        fluid(maxWidth: 1200, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }

  mugImage: 
  file(relativePath: { eq: "danielle-macinnes-222441-unsplash.jpg"}){
    childImageSharp{
      fluid(maxWidth: 1200, maxHeight: 400, quality: 70) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  accreditationLogo: 
  file(relativePath: { eq: "mncs-prof-accred-logo.png"}){
    childImageSharp{
      fixed(width: 500) {
        ...GatsbyImageSharpFixed
      }
    }
  }
}`

export default IndexPage
